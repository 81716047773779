import gql from 'graphql-tag'
import { client } from '../client'
import { TEST } from '../gql/TEST'
const GET_HT = gql`
    query GetHT {
        humanType @client
    }
`

export const getBrands = async (_root, { data }, { cache }) => {
  // const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
  const GetHT = cache.readQuery({ query: GET_HT })
  console.log('data', data)

  const wait = await client.query({
    query: TEST, variables: { ht: GetHT.humanType.toUpperCase() },
  })

  console.log('wait!', wait.data)
  cache.writeData({ data: { resolverBrands: GetHT } })
  // cache.writeQuery({ query: GET_CART_ITEMS, data });

  return null
}