import gql from 'graphql-tag'
export const TEST = gql`
    query($ht:String!){
        wetsuits( where:{humanType:{name:$ht}  brand:{name:"roxy"}}last:2 ){
            brand{name}
            productName

            price

            afilLink

        }

    }
`