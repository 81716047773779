import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { resolvers } from './resolvers'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  uri: 'https://pw-a1-eu.herokuapp.com',
  fetch,
  cache,
  resolvers,
})

cache.writeData({

  data: {
    activeScale: 'CM',
    activeAfilCountry: 'DE',

    resolverBrands: null,
    visibilityFilter: 'SHOW_ALL',
    more: 'ddd',

    loading: false,
    loadingMore: false,

    orderBy: 'updatedAt_DESC',

    activeScaleSizecharts: 'CM',

    frontzip: true,
    backzip: true,
    nozip: true,

    humanType: 'men',

    heightFeet: null,
    heightInch: null,
    height: null,
    weight: null,
    chest: null,
    waist: null,
    hips: null,
    neck: null,

    allTemperatures: true,
    tropics: true,
    summer: true,
    autumn: true,
    winter: true,

    mystic: 'mystic',
    orca: 'orca',
    billabong: 'billabong',
    hurley: 'hurley',
    ion: 'ion',
    neilpryde: 'neilpryde',
    oneill: 'o\'neill',
    quiksilver: 'quiksilver',
    ripCurl: 'rip curl',
    roxy: 'roxy',
    vissla: 'vissla',
    xcel: 'xcel',
    aquaSphere: 'aqua sphere',
    arena: 'arena',
    patagonia: 'patagonia',


    checkedBrands: [],
    uncheckedBrands: [],
    allBrands: [],
    priceMin: 0,
    priceMax: 500,
    tempMin: 0,
    tempMax: 30,
    cursor_first_page: null,

    currentPageHastNextPage: false,

    cursor_current_start: null,
    cursor_current_end: null,

    cursor_prev_start: null,
    cursor_prev_end: null,
    cursor: null,

    productAmount: 12,
    hasNextPage: false,
    results: null,
  },
})

