// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-post-jsx": () => import("../src/templates/Post/Post.jsx" /* webpackChunkName: "component---src-templates-post-post-jsx" */),
  "component---src-templates-guide-category-guide-category-jsx": () => import("../src/templates/GuideCategory/GuideCategory.jsx" /* webpackChunkName: "component---src-templates-guide-category-guide-category-jsx" */),
  "component---src-templates-wetsuit-finder-wetsuit-finder-jsx": () => import("../src/templates/WetsuitFinder/WetsuitFinder.jsx" /* webpackChunkName: "component---src-templates-wetsuit-finder-wetsuit-finder-jsx" */),
  "component---src-templates-size-finder-size-finder-jsx": () => import("../src/templates/SizeFinder/SizeFinder.jsx" /* webpackChunkName: "component---src-templates-size-finder-size-finder-jsx" */),
  "component---src-templates-guide-guide-jsx": () => import("../src/templates/Guide/Guide.jsx" /* webpackChunkName: "component---src-templates-guide-guide-jsx" */),
  "component---src-templates-home-home-jsx": () => import("../src/templates/Home/Home.jsx" /* webpackChunkName: "component---src-templates-home-home-jsx" */),
  "component---src-templates-brand-size-chart-brand-size-chart-jsx": () => import("../src/templates/BrandSizeChart/BrandSizeChart.jsx" /* webpackChunkName: "component---src-templates-brand-size-chart-brand-size-chart-jsx" */),
  "component---src-templates-size-charts-size-charts-jsx": () => import("../src/templates/SizeCharts/SizeCharts.jsx" /* webpackChunkName: "component---src-templates-size-charts-size-charts-jsx" */),
  "component---src-templates-privacy-statement-privacy-statement-jsx": () => import("../src/templates/PrivacyStatement/PrivacyStatement.jsx" /* webpackChunkName: "component---src-templates-privacy-statement-privacy-statement-jsx" */),
  "component---src-templates-terms-of-service-terms-of-service-jsx": () => import("../src/templates/TermsOfService/TermsOfService.jsx" /* webpackChunkName: "component---src-templates-terms-of-service-terms-of-service-jsx" */),
  "component---src-pages-404-jsx": () => import("../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

